@font-face {
  font-family: "PressStart";
  src: local("PressStart2P-Regular"), 
    url("./fonts/PressStart2P-Regular.ttf") format("truetype");
}

body {
  font-family: "PressStart", cursive;
}

.container {
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.container>h1, h2 {
  margin: 40px auto;
  color: var(--chouette-blue);
}
h3 {
  text-align: center;
  line-height: 25px
}

.rules-page {
  position: absolute;
  top: 15px;
  margin: 20px;
  min-height: 80%;
  z-index: 10;
  background-color: var(--light-background);
  clip-path: polygon(0% 0, 96% 0, 100% 10%, 100% 100%, 100% 100%, 4% 100%, 0% 90%, 0 0%);
}

.text-container {
  padding: 40px 60px;
  line-height: 20px;
}

.flexbox-row {
  width:100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.flexbox-column {
  height:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.flexbox-end {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.flexbox-center {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.home-logo {
  height: 40%;
  margin: 20px;
}

.large-header {
  font-size:60px;
  text-align: center;
}

.green-text{
  color: var(--chouette-green);
}

.user-form {
  width: 90%;
}
.form-content {
  margin: 0 auto;
  width: 60%;
}
.form-content>div {
  width: 100%;
  margin: 20px auto;
  font-size:15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
div.checkbox-wrapper{
  margin:0 auto;
  font-size: 12px;
  line-height: 20px;
}
input {
  width: 60%;
  padding: 5px;
  font-size:15px;
  font-family: "PressStart", cursive;
}
input.checkbox {
  width:20px;
  height:20px;
  margin-right:10px
}
sup {
  color: var(--chouette-green);
}
.image-container {
  position: relative;
  width: 40%;
  margin: 20px;
  border: 4px solid var(--white-text);
  box-sizing: border-box;
}
.image-container>img {
  width:100%;
}
.right-border {
  border: 4px solid var(--chouette-green);
  box-shadow: 2px 2px 10px var(--chouette-green)
}
.error-border {
  border: 4px solid red;
  box-shadow: 2px 2px 10px red
}
.shadow-wrapper{
  filter: drop-shadow(3px 6px 1px rgba(249,247,243, 0.5));
}
.label {
  min-width: 120px;
  text-align: center;
}
.answer {
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 10;
  width: 100%;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: var(--warn-orange);
  text-shadow: 3px 3px 3px var(--dark-background)
}
.game-button {
  width: 350px;
  height:40px;
  margin-top: 40px;
  font-size: 15px;
  font-family: 'PressStart', cursive;
  clip-path: polygon(4% 0, 96% 0, 100% 30%, 100% 70%, 96% 100%, 4% 100%, 0% 70%, 0 30%);
  background-color: var(--chouette-blue);
  border:none;
  box-shadow: 10px 5px 5px white;
  cursor: pointer;
}
.arrow-button {
  background-color: transparent;
  border: none;
  filter: drop-shadow(3px 6px 1px rgba(249,247,243, 0.5));
  cursor: pointer;
}
.chronometer {
  width: 300px;
  height:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
.timer {
  width: 98px;
  height: 44px;
  margin:0;
  position: relative;
  top: -120px;
  z-index: 10;
  background-color: var(--white-text);
  color:var(--dark-background);
  text-align: center;
  line-height: 43px;
  font-size: 18px
}

a {
  text-decoration: none; 
  test-decoration-color: none;
  color: black; 
}

.dash-line {
  flex-grow: 1;
  height: 2px;
  margin: 0 20px;
  border-bottom: 4px dashed  var(--chouette-green);
}
.dot-line {
  width:98%;
  flex-grow: 1;
  border-left: 4px dotted var(--chouette-green);
}

.alertContainer {
  position:absolute;
  top: 0;
  width: 100vw;
  min-height: 100vh;
  background-color: rgba(0,0,0,0.8);
  z-index: 20;
}
.alertBox {
  width:35%;
  padding: 20px 30px;
  clip-path: polygon(0% 0, 96% 0, 100% 10%, 100% 100%, 100% 100%, 4% 100%, 0% 90%, 0 0%);
  font-size: 12px;
  background-color: var(--light-background);
  text-align: center;
  line-height:20px;
}
.alertBox>div>button {
  width: 100px;
  height:30px;
  margin-top: 20px;
  font-size:10px;
  clip-path: polygon(8% 0, 92% 0, 100% 25%, 100% 75%, 92% 100%, 8% 100%, 0% 75%, 0 25%);
}