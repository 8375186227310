:root {
  --chouette-blue: #53c3f0;
  --chouette-green: #a6c318;
  --white-text: #F9F7F3;
  --dark-background: #3B3D40;
  --light-background: #758F93;
  --warn-orange: #E48111;
}

body {
  margin: 50px;
  background-color: var(--dark-background);
  color: var(--white-text);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
